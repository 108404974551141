import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import ProfileImage from "./ProfileImage";
import ProfileInfo from "./ProfileInfo";
import ProfilePassword from "./ProfilePassowrd";

const Profile = () => {
    return(
        <>
            <Helmet>
                <title>ReelMerge | Profile </title>
            </Helmet>
            <Navbar/>
            <section className="profile-sec">
                <div className="container">
                    <ProfileImage/>
                    <ProfileInfo/>
                    <ProfilePassword/>
                </div>
            </section>
            <Footer/>
        </>
    )
}

export default Profile;
