import React from 'react'
import webimg from '../../images/webimg.png'

function Webinar() {
    return (
        <>
          <section className="webinar-sec">
  <div className="container">
    <div className="row">
      <div className="col-md-6 d-flex align-items-center ">
        <div className="webiner-content">
          <span>REELMERGE LESSON #1</span>
          <h3 className="mt-3">
            <span>Join Us</span> for our LIVE Webinar.
          </h3>
          <p className="mt-3">
            Join us for a private training that will walk you through how to use
            ReelMerge to help you create and sell videos for maximum engagement,
            views and profits. This training or upgrade will not be made
            available afterwards. Sign up and make sure to show up on this live
            training with all your questions.
          </p>
          <a href="https://abhineos.com/training" target="_blanck">
            <button type="button" className="mt-4 medium-btn">
              Sign Up Now
            </button>
          </a>
        </div>
      </div>
      <div className="col-md-6">
        <div className="webinar-img">
          <img src={webimg} alt className="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</section>
  
        </>
    )
}

export default Webinar
