import React, {useState} from "react";
import LoginFooter from "./LoginFooter";
import {Link, useHistory} from "react-router-dom";
import logo from "../../images/LOGO.png";
import loginBg from "../../images/BG.png";
import {useDispatch} from "react-redux";
import queryString from "query-string";
import {resetPassword} from "../../actions/authAction";
import Alert from "../Alert";
import {Helmet} from "react-helmet";

const ResetPassword = ({location}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [password, setPassword] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const onInputChange = (e) =>{
        setPassword({...password, [e.target.name]: e.target.value});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(resetPassword(password, setLoader, history));
    }

    return(
        <>
            <Helmet>
                <title>ReelMerge | Reset Password </title>
            </Helmet>
            <Alert/>
            <section className="logoimg" style={{backgroundImage : "url("+loginBg+")"}}>
                <div className="container">
                    <div className="row pt-4">
                        <div className="col-md-4">
                            <div className="logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="login-box">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center">
                                <div className="login-content text-white text-center">
                                    <h1><span>Welcome</span> Back !</h1>
                                    <p>Instantly Turn Any Webpage Into a Traffic-Getting Video At The Push of a
                                        Button!</p>
                                    <h5 className="mt-3">…It’s Super Easy-To-Use</h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <form className="login-form text-white" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                                    <h4><span>Reset</span> Password</h4>
                                    <div className="form-group mt-4">
                                        <label>New Password</label>
                                        <input type="password" name="password" className="form-control"
                                               placeholder="************"
                                               required onChange={(e)=>onInputChange(e)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password</label>
                                        <input type="password" name="confirm_password" className="form-control"
                                               placeholder="************" required
                                               onChange={(e)=>onInputChange(e)}
                                        />
                                    </div>

                                    <button type="submit" className="form-control btn-change6 mt-4" disabled={loader}>
                                        { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Reset Password
                                    </button>
                                    <div className="forgot text-center mt-4">
                                        <Link to="/login" className="text-center ">
                                            Login ? <span>Click here</span></Link>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>

               <LoginFooter/>

            </section>
        </>
    )
}


export default ResetPassword;
