import React, {useState} from "react";
import videop from "../../images/videop.png";
import group24 from "../../images/Group 24.png";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import {baseURL} from "../../global/global";
import {setAlert} from "../../actions/alert";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Alert from "../Alert";
import {addMergeVideos} from "../../actions/mergeAction";

const VideoCard = ({item, fetchCampaigns}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(state => state.auth);
    const [loader, setLoader] = useState(false);
    const [buttonText, setButtonText] = useState('Delete');
    const [showDelete, setShowDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(false);

    const openDeletePopUp = (id) => {
        setDeleteId(id);
        setShowDelete(true);
    }

    const onConfirm = () => {
        deleteCampaign();
    }
    const deleteCampaign = () => {
        setButtonText('Deleting...');
        axios({
            method: "POST",
            url: `${baseURL}delete-merge`,
            data: { id: deleteId, user_id: auth.user.id },
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                fetchCampaigns();
                dispatch(setAlert(res.data.message, 'success'));
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setShowDelete(false);
            setButtonText('Delete');
        }).catch(err => {
            setShowDelete(false);
            setButtonText('Delete');
        })
    }

    const editVideo = (e, id)=>{
        e.preventDefault();
        return  history.push(`/reel-merge?project_id=${item.id}`);
    }


    return(
        <>
            <Alert />
            <div className="col-lg-3 col-md-6 mb-4">
                <div className="video-card">
                    <div className="video-img">
                        <img src={item===undefined ? videop : item.preview_image} alt="" className="img-fluid" />

                        {
                            item !== undefined ? item.status !== "Finished" ? <div className="render-overlay">{item.status}</div> : '' : ""
                        }
                        <div className="ClipsReel-overlay">

                            {item !== undefined ? <p className="mt-4 text-center text-white">{item.created}</p> : ""}

                            <ul className="list-inline m-0 px-2 d-flex justify-content-around">
                                <li className="text-center">
                                    <a href="#">
                                     </a>
                                    <div className="icon-wrapper">
                                        <a
                                            href="!#"
                                            className="action-icon edit-icon mb-1"
                                            onClick={(e)=>editVideo(e, item.id)}
                                        >
                                            <span><i className="far fa-edit"></i></span>Edit

                                        </a>

                                    </div>
                                </li>
                                {
                                    item !== undefined ?
                                        item.status==="Finished" ?
                                            <li className="text-center">
                                                <a href={`https://mergebackend.reelapps.io/download-file?url=${item.output_video}`}>
                                                    <span><i className="far fa-arrow-alt-circle-down"></i></span>
                                                    Download
                                                </a>
                                            </li>
                                        : ""
                                   : ''
                                }
                               {/* <li className="text-center">
                                    <a style={{ color: "#fff" }} className="cursor-pointer" >
                                        <span><i className="fas fa-cloud-upload-alt"></i></span>
                                        Publish
                                    </a>
                                </li>*/}
                                {
                                    item !== undefined ?
                                        <li className="text-center">
                                            <a className="cursor-pointer" onClick={() => openDeletePopUp(item.id)}><span><i className="far fa-trash-alt"></i></span>
                                                Delete
                                            </a>
                                        </li>
                                    : ''
                                }
                            </ul>
                        </div>
                    </div>
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText={buttonText}
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="light"
                        title="Are you sure?"
                        onConfirm={() => onConfirm()}
                        onCancel={() => setShowDelete(false)}
                        focusCancelBtn
                        show={showDelete}
                    >
                        You want to delete this campaign
                    </SweetAlert>

                    <div className="video-title">
                        <div className="row">

                            <div className="col-md-12 col-12 d-flex align-items-center">
                                <div className="video-name  text-white">
                                    {
                                        item !== undefined ?
                                            <p className="m-0">{item.name}</p>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default VideoCard;
