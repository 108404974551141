
const initialState = {
    data: []
}

export default function (state = initialState, action) {
    switch (action.type) {

        case 'SLIDE_VIDEO':
            return {
                ...state,
                data: [...state.data, action.payload]
            }

        default:
            return state

    }
}
