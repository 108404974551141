import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import TrainingTitles from "./TrainingTitles";
import TrainingContents from "./TrainingContents";
import Footer from "../Footer";

const Training = ({location}) => {
    return(
        <>
            <Helmet>
                <title>ReelMerge | Training</title>
            </Helmet>
            <Navbar/>

            <section className="traning-sec">
                <div className="container">
                    <div className="row">

                        <TrainingTitles location={location}/>
                        <TrainingContents location={location} />

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Training;
