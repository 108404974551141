let initialState = {
    data: false,
    dimension: '16:9',
    template_id: false
}

export default function (state = initialState, action){
    switch (action.type){

        case 'ADD_SCRIPT':
            return {
                ...state,
                data: action.payload
            }

        case 'ADD_DIMENSION':
            return {
                ...state,
                dimension: action.payload
            }

        case 'ADD_TEMPLATE_ID':
            return {
                ...state,
                template_id: action.payload
            }

        default :
            return state

    }
}