import './App.css';
import './responsive.css'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import {PrivateRoute} from "./components/PrivateRoute";
import ForgetPassword from "./components/auth/ForgetPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Profile from "./components/user/Profile";
import Projects from "./components/project/Projects";
import Privacy from "./components/user/Privacy";
import AccountManagement from "./components/user/AccountManagement";
import Upgrades from "./components/user/Upgrades";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {loadUser} from "./actions/authAction";
import HelpSupport from "./components/support/HelpSupport";
import Training from "./components/support/Training";
import TrainingArticles from "./components/support/TrainingArticles";
import MasterLogin from "./components/MasterLogin";
import Integration from "./components/integration/Integration";
import Reseller from "./components/user/Reseller";
import Reelmerge from "./components/reel/Reelmerge";



function App() {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(loadUser())
    },[])

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/" component={Login}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/forget-password" component={ForgetPassword}/>
                    <Route exact path="/reset-password" component={ResetPassword}/>
                    <Route exact path="/master-login" component={MasterLogin}/>

                    <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                    <PrivateRoute exact path="/profile" component={Profile}/>
                    <PrivateRoute exact path="/projects" component={Projects}/>
                    <PrivateRoute exact path="/privacy" component={Privacy}/>
                    <PrivateRoute exact path="/account-management" component={AccountManagement}/>
                    <PrivateRoute exact path="/reseller" component={Reseller}/>
                     <PrivateRoute exact path="/help-and-support" component={HelpSupport}/>
                    <PrivateRoute exact path="/training" component={Training}/>
                    <PrivateRoute exact path="/support-article" component={TrainingArticles}/>
{/*
                    <PrivateRoute exact path="/integration" component={Integration}/>
*/}
                    <PrivateRoute exact path="/reel-merge" component={Reelmerge} />
               
                </Switch>
            </Router>
        </div>
    );
}

export default App;
