import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AddAccount from "./AddAccount";

const AccountManagement = () => {
    return(
        <>
            <Helmet>
                <title>ClipsReel | Account Management</title>
            </Helmet>
            <Navbar/>

            <section className="add-account-sec">
                <div className="container">
                    <AddAccount/>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default AccountManagement;