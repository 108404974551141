
import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import VideoCard from "./VideoCard";
import Footer from "../Footer";
 import {baseURL} from "../../global/global";
import axios from "axios";
import {useHistory, Link} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
 import {addMergeVideos} from "../../actions/mergeAction";
import Alert from "../Alert";

const Projects = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const merges = useSelector(state => state.merge);
    const brandName = useSelector(state => state.rebrand.data);

    const [projectType, setProjectType] = useState('template');
    const [loadProject, setLoadProject] = useState(false);
    const [mergeData, setMergeData] = useState(merges.data);
    const [param, setParam] = useState('')
    const [q, setQ] = useState('');

    const redirectPage = () => {
        history.push('/reel-merge');
    }

    const changeProjectType = (e) => {
        setProjectType(e.target.value);
    }



    const fetchMerge = () => {
        axios({
            method: "POST",
            url: `${baseURL}fetch-merge-campaigns`,
            data: {},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setMergeData(res.data.data.data);
                dispatch(addMergeVideos(auth.token));
            }else{
                dispatch({type: 'ADD_CAMPAIGNS', payload: []});
            }
        }).catch(err=>{

        })
    }

    const searchCampaign = (e) => {
        setQ(e.target.value)
    }

    const search = (campaigns) => {
        return campaigns.filter(
            campaign => campaign.name.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    }







    useEffect(()=>{
        fetchMerge();
     },[]);



    return(
        <>
            <Alert />
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'ReelMerge'} | Projects</title>
            </Helmet>
            <Navbar/>

            <section className="project-banner">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-5  d-flex justify-content-lg-start justify-content-center">
                            <div className="project-header-name d-flex  text-white">
                                <div className="project-item d-flex align-items-center">
                                    <h4 className="m-0">ALL PROJECTS</h4>
                                </div>
                                {/*<div className="project-conut d-flex align-items-center">
                                    <h6 className="m-0">{projectType==="template"? search(mergeData).length : " "} Project</h6>
                                </div>*/}
                            </div>
                        </div>
                        <div className="col-lg-7  d-flex justify-content-lg-end justify-content-center">
                            <div className="project-button d-flex text-white align-items-center ">

                                <div className="project-input-box">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text"><i className="fas fa-search"></i></span>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Search Projects" onChange={(e)=>searchCampaign(e)} />
                                    </div>
                                </div>
                                {
                                    auth.user.is_client_account === "0" ?
                                        <div className="project-create-btn">
                                            <button onClick={()=>redirectPage()} type="button" className="btn-change6"><span className="mr-2">
                                        <i className="fas fa-plus-circle"></i></span> Create New Project
                                            </button>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>
                        <span className="bottom-line"></span>
                    </div>

                    {/*{
                        projectType === 'template' ?
                            <div className="row mt-5">
                                {
                                    campData.length > 0 ?
                                        search(campData).map((item,index)=>{
                                            return(
                                                <VideoCard item={item} fetchCampaigns={fetchCampaigns}  key={item.id} />
                                            )
                                        })
                                        : <div className="loader-center text-center" style={{fontSize: "20px", color: "#ffffff"}}>You have not created any project yet. Click "Create New Project" to start creating videos</div>
                                }
                                {
                                    loadProject && campData.length===0 ? <div className="loader-center text-center" style={{fontSize: "20px", color: "#ffffff"}}><i className="fa fa-spinner fa-spin mr-2"/> Fetching Campaigns</div> : ''
                                }
                                {
                                    search(campData).length < 1 && campData.length > 0  ? <div className="loader-center text-center" style={{fontSize: "20px", color: "#ffffff"}}>Sorry, you have not created any project with that name</div> : ''
                                }
                            </div>
                            : ''
                    }*/}

                            <div className="row mt-5">
                                {
                                    mergeData.length > 0 ?
                                        search(mergeData).map((item,index)=>{
                                            return(
                                                <VideoCard item={item} fetchCampaigns={fetchMerge}  key={item.id} />
                                            )
                                        })
                                        : <div className="loader-center text-center" style={{fontSize: "20px", color: "#ffffff"}}>You have not created any project yet. Click "Create New Project" to start creating videos</div>
                                }
                                {
                                    loadProject && mergeData.length===0 ? <div className="loader-center text-center" style={{fontSize: "20px", color: "#ffffff"}}><i className="fa fa-spinner fa-spin mr-2"/> Fetching Campaigns</div> : ''
                                }
                                {
                                    search(mergeData).length < 1 && mergeData.length > 0  ? <div className="loader-center text-center" style={{fontSize: "20px", color: "#ffffff"}}>Sorry, you have not created any project with that name</div> : ''
                                }
                            </div>



                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Projects;

