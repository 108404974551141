import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import TrainingTitles from "./TrainingTitles";
import queryString from "query-string";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const TrainingArticles = ({location}) => {

    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;

    const articleData = useSelector(state => state.support.articles);

    const filterArticle = articleData.filter(article => article.id === id)[0];
    console.log(filterArticle);


    return(
        <>
            <Helmet>
                <title>ReelMerge | Help & Support</title>
            </Helmet>
            <Navbar/>

            <section className="traning-sec">
                <div className="container">
                    <div className="row">
                        <TrainingTitles location={location}/>

                        <div className="col-md-8" style={{margin: "115px 0px 60px 0px"}}>
                            <div className="popular-topics-content">
                                <Link className="vidoebtn" to="/help-and-support"><span className="mr-1">
                                    <i className="fas fa-arrow-left"></i></span> Back to Video Training
                                </Link>

                                <div className="tab-content mt-4" id="v-pills-tabContent">
                                    <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                                        {
                                            filterArticle.items.length > 0 ?
                                                filterArticle.items.map((item,index)=>{
                                                    return(
                                                        item.id == art_id ?
                                                        <>
                                                            <div className="text-white"
                                                                 dangerouslySetInnerHTML={{
                                                                     __html: item.content
                                                                 }}>
                                                            </div>
                                                        </>
                                                        : ''
                                                    )
                                                })
                                            : ''
                                        }

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default TrainingArticles;
