import React, {useEffect, useState} from "react";
import icon from "../../images/ICON.png"
import icon2 from "../../images/ICON2.png"
import icon3 from "../../images/ICON3.png"
import group56 from "../../images/Group 56.png"
import image3 from "../../images/Mask-Group-3.png"
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {addScrapText} from "../../actions/videoAction";

const DashboardHead = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const auth = useSelector(state => state.auth);
    const rebrandData = useSelector(state => state.rebrand);

    const [rebrand, setRebrand] = useState(rebrandData);
    const [loader, setLoader] = useState(false);
    const [frameUrl, setFrameUrl] = useState('');
    const [showModal, setShowModal] = useState(false);

    const [blogUrl, setBlogUrl] = useState('');
    const [eComUrl, setEcomUrl] = useState('');

    const [blog, setBlog] = useState(false);
    const [ecom, setEcom] = useState(false);

    const showBlog = () => {
        setBlog(true);
    }

    const showEcom = () => {
        setEcom(true);
    }


    const addFrameUrl = () => {
        setShowModal(true);
        setFrameUrl('https://mergebackend.reelapps.io/assets/walk/ReelMerge-Walkthrough.mp4');
    }

    const closePopUp = () => {
        setFrameUrl('');
        setShowModal(false);
    }

    const onFormSubmit = (e, type) => {
        e.preventDefault();
        setLoader(true);

        let url = 'https://node.clipsreel.io/scrap?url=' + blogUrl;
        if (type === 'ecom') {
            url = 'https://node.clipsreel.io/scrap?url=' + eComUrl
        }

        axios({
            method: 'GET',
            url: url,
            headers: {'Content-Type': 'application/json'}
        }).then(res => {
            console.log(res);
            if (res.status === 200) {
                dispatch(addScrapText(res.data));
                history.push(`add-script?type=${type}`);
            }
            setBlog(false);
            setEcom(false);
            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    useEffect(() => {
        setRebrand(rebrandData);
    }, [rebrandData])

    return (
        <>
            <section className="bashboard-banner" style={{backgroundImage: "url(" + image3 + ")"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-7">
                            <div className="banner-content text-white">
                                <h4>WELCOME TO </h4>
                                {
                                    rebrand.data === false ? <h1><span>REEL</span>MERGE</h1> :
                                        <h1>{rebrand.data.name}</h1>
                                }
                                {/*<p>Create beautifully animated videos in seconds. See how ReelMerge uses its powerful AI
                                    to convert any keyword or URL to a profitable animated video in any niche.</p>*/}
                                <div className="watch-demo-btn mt-5">
                                    <button onClick={(e) => addFrameUrl()} type="button" data-toggle="modal"
                                            data-target="#myModal"><span>
                                        <i className="fas fa-play"></i></span> Watch Demo Video
                                    </button>
                                </div>

                                <div className={`modal ${showModal ? 'show-modal' : ''} `}>
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5 className="modal-title"></h5>
                                                <button onClick={(e) => closePopUp('')} type="button" className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close">
                                                    <span aria-hidden="true">
                                                        <i className="fas fa-times-circle"
                                                           style={{color: "#ffffff"}}></i></span>
                                                </button>
                                            </div>
                                            <div className="modal-body">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe id="frameSrc" className="embed-responsive-item"
                                                            src={frameUrl} allowFullScreen=""></iframe>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="col-md-12 col-lg-4 offset-lg-1 p-lg-0">
                            <div className="banner-items">
                                <Link to="/reel-merge">
                                    <div className="banner-items-list">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-3 d-flex align-items-center">
                                                <img src={icon} alt="icon"/>
                                            </div>
                                            <div className="col-md-7 col-sm-7 col-7 d-flex align-items-center">
                                                <p className="m-0">GET STARTED</p>
                                            </div>
                                            <div className="col-md-2 col-sm-2 col-2 d-flex align-items-center">
                                                <span><img src={group56} alt="right-icon"/></span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className={`modal ${blog ? 'show-modal' : ''}`}>
                <div className="modal-dialog  ecommercebox " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center mx-auto ">Blog Post</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                {/* <span aria-hidden="true" style={{color:"#fff"}}>
                                    <i className="fas fa-times-circle"></i></span> */}
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-scrollbar">

                                <form className="form-group text-center" method="post"
                                      onSubmit={(e) => onFormSubmit(e, 'blog')}>

                                    <input type="url" onChange={(e) => setBlogUrl(e.target.value)}
                                           className="form-control" placeholder={`Enter Blog Post URL`}/>
                                    <button type="button" onClick={(e) => setBlog(false)}
                                            className="mt-4 mr-5 btn-change7" disabled={loader}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="mt-4 btn-change7" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Create
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal ${ecom ? 'show-modal' : ''}`}>
                <div className="modal-dialog  ecommercebox " role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center mx-auto ">E-COMMERCE</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                {/* <span aria-hidden="true" style={{color:"#fff"}}>
                                    <i className="fas fa-times-circle"></i></span> */}
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-scrollbar">

                                <form method="post" className="form-group text-center"
                                      onSubmit={(e) => onFormSubmit(e, 'ecom')}>

                                    <input type="url" onChange={(e) => setEcomUrl(e.target.value)}
                                           className="form-control" placeholder={`Enter Product URL`}/>
                                    <button type="button" onClick={(e) => setEcom(false)}
                                            className="mt-4 mr-5 btn-change7" disabled={loader}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="mt-4 btn-change7" disabled={loader}>
                                        {loader ? <i className="fa fa-spinner fa-spin mr-2"/> : ''} Create
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardHead;
