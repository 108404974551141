import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import DashboardHead from "./DashboardHead";
import DashboardContent from "./DashboardContent";
import {Helmet} from "react-helmet";


const Dashboard = () => {
    return (
        <>
            <Helmet>
                <title>ReelMerge | Dashboard </title>
            </Helmet>
            <Navbar/>
            <DashboardHead/>
            <DashboardContent/>
            <Footer/>
        </>
    )
}

export default Dashboard;
