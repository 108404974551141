import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AddResellerAccount from "./AddResellerAccount";

const Reseller = () => {
    return(
        <>
            <Helmet>
                <title>ClipsReel | Account Management</title>
            </Helmet>
            <Navbar/>

            <section className="add-account-sec">
                <div className="container">
                    <AddResellerAccount/>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Reseller;